<template>
  <footer class="footer">
    <div class="container-fluid">
      <cookie-law
        theme="dark-lime"
        :buttonDecline=true
        buttonText="Accept"
        buttonDeclineText="Decline"
        buttonClass="cookie-btn"
        buttonDeclineClass="cookie-btn-decline"
        storageType="cookies"
        @accept="acceptCookie()"
        @decline="declineCookie()">
          <div slot="message">
            {{ msg }}
            <router-link to="/manual/privacypolicy" target="_blank">
              Privacy Policy.</router-link>
          </div>
        </cookie-law>
      <div class="row align-items-center">
        <!-- Left Column -->
        <div class="col-12 col-md-8 col-xl-6 copyright">
          <img class="logo_footer_1"
               alt="h3-logo"
               src="../../assets/images/logo_footer.png">
          <img class="logo_footer_2"
               alt="techstars-master-logo"
               src="../../assets/images/techstars-master-logo-white.png">
          <div class="copyright-text">
            © Copyright 2017 - 2020 Healthy Hip Hop Inc.
            <span>
							<router-link class="footer-item ml-2 mr-2"
                           to="/manual/termsofuse">
                TERMS
              </router-link> |
							<router-link class="footer-item ml-2 mr-2"
                           to="/manual/privacypolicy">
                PRIVACY POLICY
              </router-link>
						</span>
          </div>
        </div>

        <!-- Right Column -->
        <div class="col-12 col-md-4 col-xl-6 links">
          <span class="social"
                style="vertical-align: middle;">
            FOLLOW US ON:
          </span>
          <a class="fb-ic" href="https://www.facebook.com/healthyhiphop">
            <i class="fab fa-facebook text-white mr-3 ml-3 fa-2x"> </i>
          </a>
          <a class="tw-ic" href="https://twitter.com/Healthy_Hip_Hop">
            <i class="fab fa-twitter text-white mr-3 fa-2x"> </i>
          </a>
          <a class="ins-ic" href="https://www.instagram.com/healthy.hiphop/">
            <i class="fab fa-instagram text-white mr-3 fa-2x"> </i>
          </a>
          <span class="link-list">
						<br>
            <router-link class="footer-item mr-2"
                         @click.native="scrollToTop"
                         to="/about">
              ABOUT US
            </router-link> |
            <router-link class="footer-item ml-2 mr-5"
                         @click.native="scrollToTop"
                         to="/login">
              LOGIN
            </router-link>
					</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import CookieLaw from 'vue-cookie-law'
  export default {
    name : 'FooterBar',
    data () {
      return {
        msg : `Your satisfaction is important to us! We use cookies so our team
         can improve your overall app experience. To find out more, read our 
         updated `,
      }
    },
    methods : {
      
      /**
      * Scroll to top page
      */
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      acceptCookie(){
        window.fbq('consent', 'grant');
      },
      declineCookie(){
        window.fbq('consent', 'revoke');
      },
    },
    components : { CookieLaw },
    mounted () {
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/components/shared/footerbar';
</style>
